import React from "react";



function Faq() {



    const faqContent = [
        {
            title: "Charles A.",
            subtittle: "04/15/2023",
            descripion: "Was quick and did a good job",
        },
        {
            title: "robert S.",
            subtittle: "05/05/2022",
            descripion: "excellent...timing, responsiveness, communication, quality of work...5 stars for everything. And very fair.",
        },

        {
            title: "Jeff U.",
            subtittle: "02/20/2021",
            descripion: "concrete mortar, door repair and shutter replacement",
        },
        {
            title: "Marilyn A.",
            subtittle: "11/04/2020",
            descripion: "Excellent work.. good communication. Professional. Good price.",
        },

        {
            title: "giovanni B.",
            subtittle: "09/15/2020",
            descripion: "Rayon was extremely professional and reasonably priced. He got back to us in a timely fashion with pricing requests and was very accommodating with scheduling. The work was done quickly and efficiently. Him and his crew did rough drywall, roof repair, roof cleaning, roof painting, and soffit and siding installation. He gave us honest recommendations and kept us up to date at the end of each day’s work. We are completely satisfied with everything he and his crew have done! We will definitely hire him again for additional construction we are looking to do soon! Thanks Rayon!",
        },
        {
            title: "Nicole H.",
            subtittle: "09/14/2020",
            descripion: "wood privacy Fence",
        },

        {
            title: "Carol M.",
            subtittle: "04/30/2020",
            descripion: "No problem, on time, good price, good work",
        },
        {
            title: "Casper R.",
            subtittle: "03/29/2020",
            descripion: "They were nice people. They were fast. They were good.",
        },

    ];

    return (
        <section className="w-full h-auto p-5 mb-2 bg-gradient-to-t from-white via-[#f5f5f5] to-white">
            <section className="flex flex-wrap ">
                {faqContent.map((items, index) => {
                    return (
                        <div
                            className="p-5 shadow-xl w-[600px] h-auto mx-auto rounded-lg text-justify my-14 md:my-10 bg-white"
                            key={index}
                            data-aos="fade-up"
                            data-aos-duration="2000"
                        >
                            <div className="flex flex-col gap-4">
                                <h5 className="text-red-700 font-bold">{items.title}</h5>
                                <span className="font-bold">{items.subtittle}</span>
                                <div className="w-full border-b-[1px] border"></div>
                                <p>{items.descripion}</p>
                            </div>
                        </div>
                    );
                })}
            </section>
        </section>
    );
}

export default Faq;